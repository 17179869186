import React from "react";
import PAYWALL_CONTENT from "./index.content";
import PaywallOffer from "./PaywallOffer";
import "./index.scss";

const Paywall = ({ getLangPath, getParams }) => {
  return (
    <section className="PgIWIRP-Paywall">
      {PAYWALL_CONTENT.map(({ url, svg, price, quantity, text }, i) => (
        <PaywallOffer
          key={i}
          url={`${getLangPath}${url}${getParams}`}
          svg={svg}
          price={price}
          quantity={quantity}
          text={text}
        />
      ))}
    </section>
  );
};

export default Paywall;
