import React from "react";
import PropTypes from "prop-types";
import TrackedLink from "../../../../Analytics/TrackedLink";
import LinkSampleReport from "../../../../common/LinkSampleReport";
import "./index.scss";

const PaywallOffer = (props) => {
  const { url, svg, price, quantity, text = "Products Report" } = props;
  return (
    <div className="PgIWIRP-Paywall-Offer">
      <div className="PgIWIRP-Paywall-Offer__limit">Limited Time Deal</div>
      <img src={svg} alt={`${quantity}`} />
      <div className="PgIWIRP-Paywall-Offer__text">
        <span>{quantity} Booming</span> {text}
      </div>
      <div className="PgIWIRP-Paywall-Offer__bonus">
        <span>BONUS</span> 1 Additional Product
      </div>
      <div className="PgIWIRP-Paywall-Offer__price">{price}</div>
      <TrackedLink
        category="PricingLP"
        action="Place an order"
        class="PgIWIRP-Paywall-Offer__button"
        path={url}
        isChild
      >
        Place an order
      </TrackedLink>
      <LinkSampleReport isReportDealsPage />
      <div className="PgIWIRP-Paywall-Offer__ps">
        After the payment, you will be redirected to fill in the brief with your
        criteria
      </div>
    </div>
  );
};
PaywallOffer.propTypes = {
  svg: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default PaywallOffer;
