import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import Paywall from "../../components/iframe/wa-individual-report-paywall/Paywall";
import "../../components/iframe/wa-individual-report-paywall/index.scss";

const PgIWIRP = () => {
  const { getParams, getLangPath } = useQueryWaIframeParams();

  return (
    <Layout withTagManager={false} className="PgIWIRP">
      <h2 className="PgIWIRP__title">Order your Individual Report now!</h2>
      <Paywall getLangPath={getLangPath} getParams={getParams} />
    </Layout>
  );
};

export default PgIWIRP;
